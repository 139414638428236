import settings from '@/settings'

/**
 * LINE Messaging 相關 API
 */

class _LineMessaging {
  /**
   * /ems/line-messaging/auth-url
   * 取得 line 認證 URL
   */
  getAuthUrl() {
    return axios.get(settings.api.fullPath + '/ems/line-messaging/auth-url')
  }

  /**
   * /ems/line-messaging/binding-status
   * get binding status
   */
  getBindingStatus() {
    return axios.get(
      settings.api.fullPath + '/ems/line-messaging/binding-status'
    )
  }

  /**
   * /ems/line-messaging/callback-handler
   * line message login callback
   */
  callbackHandler(code, state) {
    return axios.post(
      settings.api.fullPath + '/ems/line-messaging/callback-handler',
      { code, state }
    )
  }

  /**
   * /ems/line-messaging/official-account-url
   * get official account url
   */
  getLineOfficialQRCodeUrl() {
    return axios.get(
      settings.api.fullPath + '/ems/line-messaging/official-account-url'
    )
  }
}

var LineMessaging = new _LineMessaging()
export default LineMessaging
