<template>
  <div class="d-flex justify-center align-center" style="height: 100vh">
    <v-sheet class="login-card" width="550px">
      <template v-if="isFetchingLineBindingStatus">
        <v-avatar size="70" color="ems-pale-blue">
          <v-icon size="60" color="warning">mdi-dots-horizontal</v-icon>
        </v-avatar>
        <div class="text-h6 mb-2 mt-5 ems-blue-grey-2--text">
          正在取得 LINE 綁定狀態，請稍後
        </div>
      </template>
      <template v-else-if="lineBindingStatus">
        <div class="d-flex align-center justify-center mt-4">
          <v-avatar size="60" color="blue lighten-4">
            <v-icon size="40" color="warning">mdi-account</v-icon>
          </v-avatar>
          <v-sheet width="100" color="transparent" class="mx-5">
            <v-progress-linear
              buffer-value="0"
              color="ems-blue-grey-2"
              height="10"
              stream
              reverse
            ></v-progress-linear>
          </v-sheet>
          <v-avatar
            size="60"
            color="line"
            class="font-weight-medium text-body-1 white--text"
          >
            LINE
          </v-avatar>
        </div>
        <div class="text-h5 font-weight-bold mt-5 ems-blue-grey-2--text">
          已成功連結 LINE 帳號
        </div>
        <div class="text-body-1 my-4 ems-blue-grey-2--text">
          掃描下方 QR Code 加入官方 LINE 帳號<br />您將可透過 LINE 收到系統告警
        </div>
        <v-img
          max-width="200"
          width="100%"
          class="ma-auto mb-8"
          :src="lineOfficialQRCodeUrl"
        >
          <template v-slot:placeholder>
            <v-sheet
              height="200"
              max-width="200"
              width="100%"
              class="d-flex justify-center align-center bg-transparent"
              style="border: 1px solid #aaa"
            >
              <v-progress-circular
                v-if="isFetchingLineOfficialQRCodeUrl"
                color="grey-lighten-4"
                indeterminate
              ></v-progress-circular>
              <div v-else-if="!lineOfficialQRCodeUrl">載入 QR Code 失敗</div>
            </v-sheet>
          </template>
        </v-img>
      </template>
      <template v-else-if="isFetchingLineLoginAuthUrl">
        <v-avatar size="70" color="ems-pale-blue">
          <v-icon size="60" color="warning">mdi-dots-horizontal</v-icon>
        </v-avatar>
        <div class="text-h6 mb-2 mt-5 ems-blue-grey-2--text">
          正在取得 LINE 綁定連結，請稍後
        </div>
      </template>
      <template v-else-if="!lineLoginAuthUrl">
        <v-avatar size="70" color="ems-pale-blue">
          <v-icon size="40" color="warning">mdi-exclamation-thick</v-icon>
        </v-avatar>
        <div class="my-5">
          <div class="text-h6 ems-blue-grey-2--text">
            取得 LINE 綁定連結失敗，請重新整理
          </div>
        </div>
        <v-btn
          class="mt-5 white--text"
          block
          large
          color="ems-blue-grey-2"
          type="submit"
          prepend-icon="mdi-refresh"
          @click="refreshPage"
          >重新整理</v-btn
        >
      </template>
      <template v-else>
        <div class="d-flex align-center justify-center mt-4">
          <v-avatar size="60" color="blue lighten-4">
            <v-icon size="40" color="warning">mdi-account</v-icon>
          </v-avatar>
          <v-sheet width="100" color="transparent" class="mx-5">
            <v-progress-linear
              buffer-value="0"
              color="ems-blue-grey-2"
              height="10"
              stream
              reverse
            ></v-progress-linear>
          </v-sheet>
          <v-avatar
            size="60"
            color="line"
            class="font-weight-medium text-body-1 white--text"
          >
            LINE
          </v-avatar>
        </div>
        <div class="text-h5 font-weight-bold mt-5 ems-blue-grey-2--text">
          連結 LINE 帳號
        </div>
        <div class="text-body-1 my-8 ems-blue-grey-2--text">
          點擊下方按鈕 進行帳號與 LINE 綁定。<br />
          綁定成功後，加入官方 LINE 帳號，您將可透過 LINE 收到系統告警。
        </div>
        <v-btn
          block
          large
          color="line"
          type="submit"
          @click="bindLineAccount"
          class="white--text"
          >綁定 LINE 帳號</v-btn
        >
      </template>

      <v-btn
        class="mt-3 mb-5"
        text
        block
        large
        color="ems-blue-grey-2"
        prepend-icon="mdi-keyboard-backspace"
        to="/"
        >返回首頁</v-btn
      >
    </v-sheet>
  </div>
</template>

<script>
import LineMessaging from '@/api/LineMessaging'

export default {
  async mounted() {
    await this.getBindingStatus()
  },
  data() {
    return {
      lineBindingStatus: false,
      isFetchingLineBindingStatus: false,
      lineLoginAuthUrl: '111',
      isFetchingLineLoginAuthUrl: false,
      lineOfficialQRCodeUrl: '',
      isFetchingLineOfficialQRCodeUrl: false
    }
  },
  methods: {
    refreshPage() {
      window.location.reload()
    },
    bindLineAccount() {
      if (this.lineLoginAuthUrl) window.location.href = this.lineLoginAuthUrl
    },
    async getBindingStatus() {
      this.isFetchingLineBindingStatus = true
      try {
        const { data } = await LineMessaging.getBindingStatus()
        this.lineBindingStatus = data
        this.isFetchingLineBindingStatus = false
        if (this.lineBindingStatus) {
          this.getLineOfficialQRCodeUrl()
        } else {
          this.getAuthUrl()
        }
      } catch (error) {
        console.error(error)
        this.isFetchingLineBindingStatus = false
      }
    },
    async getAuthUrl() {
      this.isFetchingLineLoginAuthUrl = true
      try {
        const { data } = await LineMessaging.getAuthUrl()
        this.lineLoginAuthUrl = data
      } catch (error) {
        console.error(error)
      }
      this.isFetchingLineLoginAuthUrl = false
    },
    async getLineOfficialQRCodeUrl() {
      this.isFetchingLineOfficialQRCodeUrl = true
      try {
        const { data } = await LineMessaging.getLineOfficialQRCodeUrl()
        this.lineOfficialQRCodeUrl = data
      } catch (error) {
        console.error(error)
      }
      this.isFetchingLineOfficialQRCodeUrl = false
    }
  }
}
</script>

<style lang="scss" scoped>
.login-card {
  backdrop-filter: blur(10px);
  background-color: rgb(232, 235, 237, 0.3) !important;
  border: 2px solid white !important;
  border-radius: 24px !important;
  text-align: center;
  padding: 60px;
  width: 460px;
  max-width: calc(100% - 40px);
}
</style>
